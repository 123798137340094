import React from 'react';
import { Col, Container, ResponsiveEmbed, Row } from 'react-bootstrap';
import { ActionButton, Disqus, Footer, Layout, Navbar, PageHeader, Sponsor } from '@components';
import { site } from '@variables';
import CoopAtAGlance from '@assets/documents/co-op/2023-IT_coop_at_a_glance.pdf';
import FulltimeCoopBrochure from '@assets/documents/co-op/Full-time-Co-op-Brochure.pdf';
import TechSkills from '@assets/documents/co-op/TechSkills-all-students.pdf';

const CoOpPage = () => <Layout className="about-page">
  <Navbar />
  <PageHeader
    title="SoIT - Co-Op Program"
  />

  <Container style={{ marginBottom: `1rem`, marginTop: `3rem` }}>
    <Row className="justify-content-center">
      <Col md={8}>
        <ResponsiveEmbed aspectRatio="16by9" className="ml-md-2 mb-3">
          <iframe
            title="Explore our Co Op Program"
            src={`https://player.vimeo.com/video/698166696?h=2ae9aced5a`}
            className="embed-responsive-item"
            allow="autoplay; fullscreen"
            allowFullScreen />
        </ResponsiveEmbed>
      </Col>
    </Row>

    <Row className="justify-content-center mb-3">
      <a href={CoopAtAGlance} download="2023-IT_coop_at_a_glance.pdf">
        <ActionButton>Co-op at a glance</ActionButton>
      </a>
      <a href={TechSkills} download="Tech Skills All Students.pdf">
        <ActionButton>Tech Skills for All Students</ActionButton>
      </a>
      <a href={FulltimeCoopBrochure} download="Full-time Co-op Brochure.pdf">
        <ActionButton>Hire a Full-time Co-op</ActionButton>
      </a>
    </Row>
    <Disqus
      identifier="about-co-op-2023"
      title="SoIT - Co-Op Program"
      pageLocation={`${site.url}/about/co-op`}
    />
  </Container>

  <Sponsor />
  <Footer />
</Layout>;

export default CoOpPage;
